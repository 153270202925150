import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ade5b65a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "login-container"
};
const _hoisted_2 = {
  class: "title-container"
};
const _hoisted_3 = {
  class: "title"
};
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'index',
  setup(__props) {
    const store = useStore();
    const form = ref({
      username: 'admin',
      password: '88888888'
    });
    const rules = ref({
      username: [{
        required: true,
        message: '请输入用户名',
        trigger: 'blur'
      }],
      password: [{
        required: true,
        message: '请输入密码',
        trigger: 'blur'
      }]
    });
    const formRef = ref(null);
    const handleLogin = () => {
      formRef.value.validate(async valid => {
        if (valid) {
          store.dispatch('app/login', form.value);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    };
    const passwordType = ref('password');
    const changeType = () => {
      if (passwordType.value === 'password') {
        passwordType.value = 'text';
      } else {
        passwordType.value = 'password';
      }
    };
    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_form_item = _resolveComponent("el-form-item");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form = _resolveComponent("el-form");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_form, {
        ref_key: "formRef",
        ref: formRef,
        model: form.value,
        class: "login-form",
        rules: rules.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('login.title')), 1)]), _createVNode(_component_el_form_item, {
          prop: "username"
        }, {
          default: _withCtx(() => [_createVNode(_component_svg_icon, {
            icon: "user",
            class: "svg-container"
          }), _createVNode(_component_el_input, {
            modelValue: form.value.username,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => form.value.username = $event)
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "password"
        }, {
          default: _withCtx(() => [_createVNode(_component_svg_icon, {
            icon: "password",
            class: "svg-container"
          }), _createVNode(_component_el_input, {
            modelValue: form.value.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => form.value.password = $event),
            type: passwordType.value
          }, null, 8, ["modelValue", "type"]), _createVNode(_component_svg_icon, {
            icon: passwordType.value === 'password' ? 'eye' : 'eye-open',
            onClick: changeType
          }, null, 8, ["icon"])]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          class: "login-button",
          onClick: handleLogin
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('login.btnTitle')), 1)]),
          _: 1
        })]),
        _: 1
      }, 8, ["model", "rules"])]);
    };
  }
};